<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  data() {
    return {
      linechart: {
        labels: ["Juin 2020", "Juil. 2020", "Aout 2020", "Sept. 2020", "Octo. 2020"],
        datasets: [{
          backgroundColor: "rgba(84, 79, 255, 0.5)",
          borderColor: "#544fff",
          strokeColor: "#544fff",
          pointBackgroundColor: "#544fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#fff",
          pointHighlightStroke: "#000",
          data: [900, 3000, 80, 81, 4000, 2500, 2000],
          pointRadius: 0
        }, {
          backgroundColor: "rgba(21, 141, 247, 0.3)",
          borderColor: "#158df7",
          strokeColor: "#158df7",
          pointBackgroundColor: "#158df7",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#000",
          pointHoverBorderColor: "#000",
          pointHighlightStroke: "rgba(30, 166, 236, 1)",
          data: [1000, 2000, 40, 19, 2000, 2500, 2000],
          pointRadius: 0
        }]
      },
      options: {
        animation: {
          duration: 1000,
          easing: 'linear'
        },
        maintainAspectRatio: false,
        responsive: true,
        showPoint: false,
        scales: {
          yAxes: [{ticks: { beginAtZero: true, stepSize: 1000,}}],
          xAxes: [{barThickness: 39,}],
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },

      }
    }
  },
  mounted () {
    this.renderChart(this.linechart, this.options)
  }
}
</script>

<style>
</style>